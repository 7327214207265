import GATSBY_COMPILED_MDX from "/opt/build/repo/essays/0001-how-to-get-promoted-as-a-software-engineer/essay.mdx";
import Layout from "../components/Layout";
import React from "react";
import {graphql} from "gatsby";
import Sidebar from "../components/Sidebar";
import EmailSubscriptionWidget from "../components/EmailSubscriptionWidget";
const EssayTemplate = props => {
  return React.createElement(Layout, null, React.createElement("div", {
    className: "grid grid-cols-1 lg:grid-cols-5"
  }, React.createElement("article", {
    className: "max-w-4xl lg:col-span-4 mb-4 lg:mb-0"
  }, props.children, React.createElement(EmailSubscriptionWidget)), React.createElement("div", {
    className: "lg:col-span-1"
  }, React.createElement(Sidebar, {
    longTwitter: true
  }))));
};
export const query = graphql`
query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        categories
        date(formatString: "MMM Do, YYYY")
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      excerpt(pruneLength: 200)
    }
  }
`;
EssayTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(EssayTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
